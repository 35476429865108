import React, { useState } from "react";
import "./developers.scss";
import { DocsSidebarNav } from "./components/ScrollArea";
import { docsConfig } from "./config/doc";
import { ScrollArea } from "@radix-ui/react-scroll-area";
import { PanelLeftOpen } from "lucide-react";

function Layout({ children }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="">
      <PanelLeftOpen
        color="#fff"
        className="md:hidden -ml-2 mt-3"
        onClick={toggleSidebar}
      />
      <div className="container flex-1 items-start md:grid md:grid-cols-[220px_minmax(0,1fr)] md:gap-6 lg:grid-cols-[240px_minmax(0,1fr)] lg:gap-10">
        <aside
          className={`bg-black absolute z-30 -ml-2 h-[calc(100vh-3.5rem)] w-full shrink-0 md:sticky md:block transition-transform duration-300 ${
            isSidebarOpen ? "-translate-x-10" : "-translate-x-[1000px]"
          } md:translate-x-0`}
        >
          <ScrollArea className="h-full py-6 pr-6 lg:py-8">
            <DocsSidebarNav items={docsConfig.sidebarNav} />
          </ScrollArea>
        </aside>
        <div className="flex border-l border-l-[#58595b7d] pl-10 -ml-20 md:-ml-10 min-h-screen">
          {children}
        </div>
      </div>
    </div>
  );
}

export default Layout;
