import React, { useEffect, useState } from "react";
import "../CreateAccount/create_account.scss";
import { useLocation } from "react-router-dom";
import Register from "./pages/register";

function Login() {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const [fadeInAni, setFadeInAni] = useState("fade");
  const [page, setPage] = useState(0);

  let query = useQuery();
  // console.log(query.get("page"));
  let qpage = query.get("page");

  useEffect(() => {
    if (qpage == null) {
      setPage(0);
    }
    setPage(Number(qpage));
  }, [qpage]);

  useEffect(() => {
    if (page === 0) {
      window.history.pushState({}, "", window.location.pathname + "?page=0");
    }
    if (page === 1) {
      window.history.pushState({}, "", window.location.pathname + "?page=1");
    }
    if (page === 2) {
      window.history.pushState({}, "", window.location.pathname + "?page=2");
    }
  }, [page]);

  useEffect(() => {
    setFadeInAni(fadeInAni === "fade" ? "fade2" : "fade");
  }, []);

  return (
    <React.Fragment>
      <Register setPage={setPage} page={page} />
    </React.Fragment>
  );
}

export default Login;
