import React, { useState, useRef } from "react";
import "../Footer/footer.scss";
// import Zendesk from "react-zendesk";

function Footer() {
  const refdiv = useRef([]);
  const [click, setClick] = useState(false);
  const [isopen, setIsOpen] = useState(false);

  // function changeclassNameexit() {
  //   // let box = refdiv;
  //   // let box1 = document.querySelectorAll(".exit");
  //   // console.log("div", box, box1);
  //   let box = document.querySelectorAll(".exit");
  //   console.log("box1", box);
  //   Object.entries(box).map((item, i) => {
  //     if (item[1].classNameList.contains("hidden")) {
  //       item[1].classNameList.remove("hidden");
  //       setTimeout(function () {
  //         box.classNameList.remove("visual");
  //       }, 20);
  //     } else {
  //       item[1].classNameList.add("visual");
  //       item[1].addEventListener(
  //         "transitionend",
  //         function (e) {
  //           item[1].classNameList.add("hidden");
  //         },
  //         {
  //           capture: false,
  //           once: true,
  //           passive: false,
  //         }
  //       );
  //     }
  //     return <></>;
  //   });
  // }

  return (
    <React.Fragment>
      <footer className="section-footer">
        <div className="chat-bubble"></div>
        <div className="container">
          <div className="footer-content">
            <div className="footer-contact">
              <div className="contact" onClick={() => setClick(!click)}>
                {/*<div className="contact">*/}
                <p className="m-0 fs-22 ff-regular">Contact</p>
                {click ? (
                  <div
                    className="hamburger1 is-active"
                    id="hamburger1"
                    onClick={() => setIsOpen(!isopen)}
                  >
                    <span className="line-res1"></span>
                    <span className="line-res1"></span>
                  </div>
                ) : (
                  <div className="hamburger1" onClick={() => setIsOpen(isopen)}>
                    <span className="line-res1"></span>
                    <span className="line-res1"></span>
                  </div>
                )}
              </div>

              {click ? (
                <div
                  className={`contact-desc ismenuopen d-flex`}
                  id="contactmenuid"
                >
                  <div className="c-desc">
                    <a
                      href="https://goo.gl/maps/YJEwV4GW8pXfqzhs5"
                      target="_blank"
                    >
                      <div className="effect1">
                        <i className="fa-solid fa-location-dot"></i>
                        <h3>
                          <span className="d-block">
                            The Rhombus, Plot 24 Tumu Avenue
                          </span>
                          <span className="d-block">Kanda Estates, Accra</span>
                        </h3>
                      </div>
                    </a>
                  </div>
                  <div className="c-desc">
                    <div className="effect2">
                      <h3>+233 55 967 0549</h3>
                      <h3>+233 30 222 7698</h3>
                    </div>
                  </div>
                  <div className="c-desc">
                    <div className="effect3">
                      <a href="mailto: clientservices@blackstargroup.ai">
                        clientservices@blackstargroup.ai
                      </a>
                      <a href="mailto: info@blackstargroup.ai">
                        info@blackstargroup.ai
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className={`contact-desc exit d-flex`}
                  id="contactmenuid"
                  ref={refdiv}
                >
                  <div className="c-desc">
                    <div className="effect1">
                      <i className="fa-solid fa-location-dot"></i>
                      <h3>
                        <span className="d-block">
                          The Rhombus, Plot 24 Tumu Avenue
                        </span>
                        <span className="d-block">Kanda Estates, Accra</span>
                      </h3>
                    </div>
                  </div>
                  <div className="c-desc">
                    <div className="effect2">
                      <h3>+233 55 967 0549</h3>
                      <h3>+233 30 222 7698</h3>
                    </div>
                  </div>
                  <div className="c-desc">
                    <div className="effect3">
                      <a href="mailto: clientservices@blackstargroup.ai">
                        clientservices@blackstargroup.ai
                      </a>
                      <a href="mailto: info@blackstargroup.ai">
                        info@blackstargroup.ai
                      </a>
                    </div>
                  </div>
                </div>
              )}
              {/*{click ? (*/}
              {/*    <div className="footer-text-anim1 hide ">*/}
              {/*        The contents of this website are believed to be reliable, but the accuracy or*/}
              {/*        completeness of any information contained herein is not guaranteed and members of*/}
              {/*        Black Star Group shall not in any way be liable for any delay in keeping such*/}
              {/*        information current. Members of Black Star Group specifically disclaim at*/}
              {/*        warranties, express or implied, except for those derived from its executed Customer*/}
              {/*        Agreements.*/}
              {/*    </div>*/}
              {/*) : (*/}
              {/*    <div className="footer-text-anim2 show">*/}
              {/*        The contents of this website are believed to be reliable, but the accuracy or*/}
              {/*        completeness of any information contained herein is not guaranteed and members of*/}
              {/*        Black Star Group shall not in any way be liable for any delay in keeping such*/}
              {/*        information current. Members of Black Star Group specifically disclaim at*/}
              {/*        warranties, express or implied, except for those derived from its executed Customer*/}
              {/*        Agreements.*/}
              {/*    </div>*/}
              {/*)}*/}
            </div>
            <div className="copyright">
              <div className="social-icons">
                <a
                  href="http://wa.me/233596994904"
                  target="_blank"
                  className="footer-icon"
                >
                  <i className="fa-brands fa-whatsapp"></i>
                </a>
                <a
                  href="https://gh.linkedin.com/company/bsgghana"
                  target="_blank"
                  className="footer-icon"
                >
                  <i className="fa-brands fa-linkedin-in"></i>
                </a>
                <a
                  href="https://mobile.twitter.com/bsgghana"
                  target="_blank"
                  className="footer-icon"
                >
                  <i className="fa-brands fa-twitter"></i>
                </a>
                <a
                  href="https://www.instagram.com/bsgghana/"
                  target="_blank"
                  className="footer-icon"
                >
                  <i className="fa-brands fa-instagram"></i>
                </a>
                <a
                  href="https://www.youtube.com/"
                  target="_blank"
                  className="footer-icon"
                >
                  <i className="fa-brands fa-youtube"></i>
                </a>
              </div>
              <p className="m-0 fs-16 ff-light cpy">© 2022, Black Star Group</p>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
}

export default Footer;
