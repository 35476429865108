import React, { useState, useEffect } from "react";
import "../Solution/solution.scss";
import data from "../../../data";
import { useParams, Link, useLocation } from "react-router-dom";

function Solution(props) {
  const params = useParams();
  const params1 = useLocation();
  const [animationClass, setAnimationClass] = useState("fadeUp");
  useEffect(() => {
    if (animationClass === "fade") {
      setAnimationClass("fade2");
    } else {
      setAnimationClass("fade");
    }
  }, [params1.pathname]);
  return (
    <React.Fragment>
      <div
        className={`section-solution ${
          animationClass === "fade" ? "fade2" : "fade"
        }`}
        id="product-main"
      >
        {data
          ?.filter((item) => item.title === props.props.title)
          .map((subdata, index) => {
            // console.log("subdata", subdata);
            return (
              <React.Fragment key={index}>
                <div className="product-solution">
                  {subdata?.solution?.map((head, i) => {
                    return <h2 key={i}>{head.heading}</h2>;
                  })}
                </div>
                <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample"
                  key={index}
                >
                  {subdata.subData?.map((val, i) => {
                    return (
                      <div className="accordion-item" key={i}>
                        <Link
                          to={`/${props.props.title}/solution/${i + 1}`}
                          className="text-decoration-none text-white"
                        >
                          <h2
                            className="accordion-header"
                            id="flush-headingTwo"
                          >
                            <button
                              className={
                                params.id === val.subcatID
                                  ? "accordion-button"
                                  : "accordion-button collapsed"
                              }
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#flush-${val.subcatID}`}
                              aria-expanded="false"
                              aria-controls={`flush-${val.subcatID}`}
                            >
                              <div className="resacc-div">
                                <span className="acc-title">
                                  {val.subtitle}
                                </span>
                                <span className="acc-tag">
                                  {val.description}
                                </span>
                              </div>
                            </button>
                          </h2>
                        </Link>
                        <div
                          id={`flush-${val.subcatID}`}
                          className={
                            params.id === val.subcatID
                              ? "accordion-collapse collapse show"
                              : "accordion-collapse collapse"
                          }
                          aria-labelledby="flush-headingTwo"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">
                            {val.innersubdata?.map((inner, subindex) => {
                              return (
                                <div className="inner" key={subindex}>
                                  {inner.innerdesc?.map(
                                    (innerdesc, nestindex) => (
                                      <div
                                        className="innerdesc-para"
                                        key={nestindex}
                                      >
                                        <p>{innerdesc.bullet1}</p>
                                        <p>{innerdesc.bullet2}</p>
                                      </div>
                                    )
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </React.Fragment>
            );
          })}
      </div>
    </React.Fragment>
  );
}

export default Solution;
