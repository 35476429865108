import React, { useEffect, useState } from "react";
import "./investment_goal.scss";
import { useNavigate } from "react-router-dom";
import { MutualFundCard } from "./CardCon";
import { INDX_KEYS } from "../IFunds/IFunds";
import { getDeviceType } from "../../../src/lib/utils";

export const INVESTMENT_GOAL_IDX = [
  {
    name: "GMSI",
    fullname: "Global Multi Sector Index",
    description: "Tracks global equity markets across multiple sectors.",
    more: "",
    type: "Equity",
    key: 1,
  },
  {
    name: "GHETR",
    fullname: "Ghana Equity Total Return Index",
    description:
      "Comprehensive view of Ghana’s stock market performance, including dividends.",
    more: "",
    type: "Equity",
    key: 2,
  },
  {
    name: "GTBX",
    fullname: "Treasury Index",
    description: "Track returns on government bills.",
    more: "",
    type: "Fixed",
    key: 3,
  },
  {
    name: "DBX",
    fullname: "Domestic Bond Index",
    description:
      "Monitor the performance of Cedi-denominated sovereign bonds on GFIM.",
    more: "",
    type: "Fixed",
    key: 4,
  },
  {
    name: "GEBX",
    fullname: "Ghana Eurobond Index",
    description:
      "Measure the performance of Ghana’s Eurobonds in U.S. dollars.",
    more: "",
    type: "Fixed",
    key: 5,
  },
];

function Indicies() {
  const navigate = useNavigate();
  const [fadeInAni, setFadeInAni] = useState("fade");

  useEffect(() => {
    setFadeInAni(fadeInAni === "fade" ? "fade2" : "fade");
  }, []);

  return (
    <React.Fragment>
      <div className={`investment_goals mt-4 ${fadeInAni} mb-5`}>
        <h1>Our Indices</h1>
        <div className="methodology_head d-flex">
          <p
            className="m-0 align-self-center"
            onClick={() => {
              navigate("/our_methodology");
            }}
          >
            Investment Focus
          </p>
        </div>
        <div
          className={`text-start text-white mt-5 grid ${
            getDeviceType() == "mobile" ? "grid-cols-1" : "grid-cols-2"
          }  gap-10 w-full`}
        >
          <div className="flex flex-col gap-4">
            <div
              style={{
                borderBottom: "solid #212529 0.5px",
              }}
              className="min-h-fit w-full p-4 flex flex-col justify-between"
            >
              Fixed Income
            </div>
            {INVESTMENT_GOAL_IDX?.filter((x) => x?.type === "Fixed")?.map(
              (data, index) => {
                return (
                  <div className="col-span-1">
                    <MutualFundCard
                      key={index}
                      name={data?.name}
                      fullname={data?.fullname}
                      description={data?.description}
                      ytd={0}
                      to={`/idx?menu=${data?.key}`}
                      menu={INDX_KEYS[data?.key - 1]}
                    />
                  </div>
                );
              }
            )}
          </div>

          <div className="flex flex-col gap-4">
            <div
              style={{
                borderBottom: "solid #212529 0.5px",
              }}
              className="min-h-fit w-full p-4 flex flex-col justify-between"
            >
              Equity
            </div>
            {INVESTMENT_GOAL_IDX?.filter((x) => x?.type === "Equity")?.map(
              (data, index) => {
                return (
                  <div className="col-span-1">
                    <MutualFundCard
                      key={index}
                      name={data?.name}
                      fullname={data?.fullname}
                      description={data?.description}
                      ytd={0}
                      to={`/idx?menu=${data?.key}`}
                      menu={INDX_KEYS[data?.key - 1]}
                    />
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Indicies;
