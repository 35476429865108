import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import moment from "moment";
import "moment-timezone";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const constants = {
  API_URL: "https://api.gnii.ai/api",
  GNII_URL: "https://api.uatdev.gnii.ai/api",
  TOKEN_COOKIE_NAME: "authToken",
  EEBF: "b6266457-2123-4c54-8a01-a603318cbb4f",
  FIAP: "568425df-ff6d-495a-b6d9-304240a0d82c",
  CCMF: "d972e50f-d054-4dda-9790-0c941915a4cc",
  GMSI: "3914ff08-1b5a-426e-916c-2a4c761502ba",
  DELTA: "3b9556ec-f64d-49bc-b084-e78b01a479d2",
  GHETR: "305263fc-9a98-49cc-98de-3b6cd0ae4c63",
  GHEPR: "3fad608d-4714-4443-8420-6f3a4dd7f38e",
  PBF: "f3856689-bc47-4526-b27a-7e4be8d53957",
  PIF: "0fe0c592-3d42-475a-bb93-146807dd7d46",
  GTBX: "1cceaa62-f5c5-44be-9866-55a539decce6",
  DBX: "fcadd9cd-bfe5-4370-9424-69206e283621",
  GEBX: "2d439f8e-41ca-446c-a234-bebc67f3be91",
  ACCESS_TOKEN:
    "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJYRzh5YUxhMDlBalptRXVZX3BzWHNvbVJFWVJQMFhCZzNTd3UxNnB1NENnIn0.eyJleHAiOjE3MjEyMDc4MDgsImlhdCI6MTcyMTIwNzUwOCwianRpIjoiYTQ0YzEwZjktNDY3NS00OTY1LWI2ODMtYjZiOWY4ZjQzNGY3IiwiaXNzIjoiaHR0cHM6Ly9hdXRoLmJsYWNrc3Rhcmdyb3VwLmFpL3JlYWxtcy9nbmlpLXdlYWx0aCIsImF1ZCI6WyJyZWFsbS1tYW5hZ2VtZW50IiwiYWNjb3VudCJdLCJzdWIiOiJiMmQ5ZTA5Ni03ZTk2LTRiNmEtYTIxOC00MWVlOTc5N2FkODEiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJtb2JpbGUtYXBwIiwic2Vzc2lvbl9zdGF0ZSI6ImNjMGI4ZWZjLTRmZjQtNGM0ZC1hNGYyLTQwZTg3MzZiNWM1MCIsImFjciI6IjEiLCJhbGxvd2VkLW9yaWdpbnMiOlsiLyoiXSwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbImRlZmF1bHQtcm9sZXMtZ25paS13ZWFsdGgiLCJvZmZsaW5lX2FjY2VzcyIsInVtYV9hdXRob3JpemF0aW9uIl19LCJyZXNvdXJjZV9hY2Nlc3MiOnsicmVhbG0tbWFuYWdlbWVudCI6eyJyb2xlcyI6WyJ2aWV3LXVzZXJzIiwicXVlcnktZ3JvdXBzIiwicXVlcnktdXNlcnMiXX0sImFjY291bnQiOnsicm9sZXMiOlsibWFuYWdlLWFjY291bnQiLCJtYW5hZ2UtYWNjb3VudC1saW5rcyIsInZpZXctcHJvZmlsZSJdfX0sInNjb3BlIjoiZW1haWwgcHJvZmlsZSIsInNpZCI6ImNjMGI4ZWZjLTRmZjQtNGM0ZC1hNGYyLTQwZTg3MzZiNWM1MCIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJuYW1lIjoiYWRtaW4gdXNlciIsImdyb3VwcyI6WyJUTjM5ODA5NiIsIlZTNjAxODc3IiwiVlkyMzIxMjEiXSwicHJlZmVycmVkX3VzZXJuYW1lIjoiYWRtaW4iLCJnaXZlbl9uYW1lIjoiYWRtaW4iLCJkZXBhcnRtZW50IjoiYnNhIiwiZmFtaWx5X25hbWUiOiJ1c2VyIiwiZW1haWwiOiJhZG1pbkBibGFja3N0YXJncm91cC5haSIsInRlbmFudCI6ImJsYWNrc3RhciJ9.APQqaxDsZsUpkmqmzMHzULtZSQf6-s5GB8e-3ECV9YmS_vlmd2AwIexwPKgTGy-IJcy30fSXyyH_qQw1HmERIMOrGiCnPZ8jVE32k48DtXQhHUiOXDuwqfur5JH-weWDKXtaUZSukqD0nEUNfid2wKM1MyxDiviB4KCqBLOS3rrTs3BlBUbvvB5dWTETptU2wdiBns6z6WKaDtF3BxcCE4kIYE88HVdY9Iz99gOWu8oDKyEi1jcuBrbzw0NOABi1QKPPqgmRP04Lr7CSPPzBWWLz7uda-Cnkx1AcvjQjrFC_od6fL6vzjsCk_TtVLsaT0TrMZPI-G_cPxOFvFzSl3Q",
  REFRESH_TOKEN:
    "eyJhbGciOiJIUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICIwYmE0ZjlhNy0xMjc2LTRiOWYtYmIzZC04M2RjNWIwNWJlN2QifQ.eyJleHAiOjE3MjM3OTk1MDgsImlhdCI6MTcyMTIwNzUwOCwianRpIjoiYTBjMjc4ZmQtZGI5ZS00NTM4LTgzYjMtZTk4OWRhOGRhOWY0IiwiaXNzIjoiaHR0cHM6Ly9hdXRoLmJsYWNrc3Rhcmdyb3VwLmFpL3JlYWxtcy9nbmlpLXdlYWx0aCIsImF1ZCI6Imh0dHBzOi8vYXV0aC5ibGFja3N0YXJncm91cC5haS9yZWFsbXMvZ25paS13ZWFsdGgiLCJzdWIiOiJiMmQ5ZTA5Ni03ZTk2LTRiNmEtYTIxOC00MWVlOTc5N2FkODEiLCJ0eXAiOiJSZWZyZXNoIiwiYXpwIjoibW9iaWxlLWFwcCIsInNlc3Npb25fc3RhdGUiOiJjYzBiOGVmYy00ZmY0LTRjNGQtYTRmMi00MGU4NzM2YjVjNTAiLCJzY29wZSI6ImVtYWlsIHByb2ZpbGUiLCJzaWQiOiJjYzBiOGVmYy00ZmY0LTRjNGQtYTRmMi00MGU4NzM2YjVjNTAifQ.sUZcoLzpcIB0BFovX2heO5r608jMyg8AhNAdRdoW8jA",
};

export async function checkAccessToken() {
  const accessToken = sessionStorage.getItem("accessToken");
  const refreshToken = sessionStorage.getItem("refreshToken");

  if (!accessToken || !refreshToken) {
    // User is not authenticated
    // return;
    sessionStorage.setItem("accessToken", constants.ACCESS_TOKEN);
    sessionStorage.setItem("refreshToken", constants.REFRESH_TOKEN);
  }

  // Decode the JWT to get the expiration time
  const { exp } = JSON.parse(atob(accessToken.split(".")[1]));

  if (exp * 1000 < Date.now()) {
    // Access token has expired, refresh it
    const response = await fetch(
      constants.API_URL + "/public/user/refresh-token",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refreshToken }),
      }
    );

    const data = await response.json();

    // console.log(data, "????");

    if (data.accessToken) {
      // Update the stored access token
      sessionStorage.setItem("accessToken", data.accessToken);
      sessionStorage.setItem("refreshToken", data.refreshToken);
    } else {
      // Handle refresh token failure, maybe redirect to login
      // console.log("Dsd");
    }
  }
}

export function SnakeToNormal(snakeStr) {
  // Split the string by underscores
  const words = snakeStr?.toLowerCase()?.split("_");
  // Capitalize the first letter of each word
  const capitalizedWords = words?.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  // Join the words with a space
  const normalStr = capitalizedWords.join(" ");
  return normalStr;
}

export function GetDayOfYear() {
  const date = new Date();
  // Create a new date object for January 1st of the same year
  const start = new Date(date.getFullYear(), 0, 1);
  // Calculate the difference in milliseconds
  const diff = date - start;
  // Convert milliseconds to days
  const dayOfYear = Math.floor(diff / (1000 * 60 * 60 * 24)) + 1;
  return dayOfYear;
}

export function removeShortName(str) {
  // Use a regular expression to find the short name pattern at the end of the string
  return str.replace(/\s*\([^)]+\)$/, "");
}

export function isMarketOpen(type) {
  // Check if today is a weekend or US holiday

  if (type) {
    const now2 = new Date();
    if (isWeekend(now2) || isUSHoliday(now2)) {
      return false;
    }

    // Get the current time in New York
    const now = moment().tz("America/New_York");

    // Market opens at 9:30 AM and closes at 4:00 PM
    const marketOpenTime = moment.tz("09:30", "HH:mm", "America/New_York");
    const marketCloseTime = moment.tz("16:00", "HH:mm", "America/New_York");

    // Check if the current time is within the market hours
    if (now.isBetween(marketOpenTime, marketCloseTime, null, "[]")) {
      return true;
    } else {
      return false;
    }
  } else {
    // Get the current time in New York
    const now = moment().tz("Africa/Accra");

    // Market opens at 9:30 AM and closes at 4:00 PM
    const marketOpenTime = moment.tz("08:30", "HH:mm", "Africa/Accra");
    const marketCloseTime = moment.tz("17:30", "HH:mm", "Africa/Accra");

    // Check if the current time is within the market hours
    if (now.isBetween(marketOpenTime, marketCloseTime, null, "[]")) {
      return true;
    } else {
      return false;
    }
  }
}

export let colors = [
  "rgb(227, 26, 86)",
  "rgb(63, 200, 228)",
  "rgb(63, 228, 129)",
  "rgb(29, 191, 115)",
  "rgb(22, 131, 102)",
  "rgb(255, 201, 10)",
  "rgb(255, 159, 10)",
  "rgb(251, 59, 82)",
  "rgb(228, 63, 211)",
  "rgb(86, 84, 212)",
  "rgb(63, 139, 228)",
  "rgb(163, 182, 228)",
  "rgb(263, 139, 228)",
  "rgb(363, 119, 128)",
  "rgb(63, 30, 128)",
  "rgb(33, 135, 128)",
  "rgb(33, 135, 18)",
  "rgb(123, 13, 28)",
];

export function getMarketStatusColor(timestamp) {
  const date = new Date(timestamp);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();

  const timeInMinutes = hours * 60 + minutes;

  // Pre-market: 4:00 AM to 9:30 AM
  const preMarketStart = 4 * 60;
  const preMarketEnd = 9 * 60 + 30;

  // Market open: 9:30 AM to 4:00 PM
  const marketOpenStart = 9 * 60 + 30;
  const marketOpenEnd = 16 * 60;

  // Post-market: 4:00 PM to 8:00 PM
  const postMarketStart = 16 * 60;
  const postMarketEnd = 20 * 60;

  if (timeInMinutes >= preMarketStart && timeInMinutes < preMarketEnd) {
    return "red";
  } else if (
    timeInMinutes >= marketOpenStart &&
    timeInMinutes < marketOpenEnd
  ) {
    return "green";
  } else if (
    timeInMinutes >= postMarketStart &&
    timeInMinutes < postMarketEnd
  ) {
    return "yellow";
  } else {
    return "gray";
  }
}

export const getDeviceType = () => {
  if (typeof window !== "undefined") {
    const width = window.innerWidth;

    if (width < 768) {
      return "mobile";
    } else if (width >= 768 && width < 1024) {
      return "tablet";
    } else {
      return "desktop";
    }
  }

  return "desktop"; // Default to desktop if window is undefined (e.g., during server-side rendering)
};

export function removePLC(str) {
  // Check if the string ends with "PLC" (case-insensitive)
  if (str.trim().toUpperCase().endsWith("PLC")) {
    // Remove "PLC" from the end and trim any trailing whitespace
    return str.slice(0, -3).trim();
  }
  // Return the string unchanged if it doesn't end with "PLC"
  return str;
}

export function percentageDifference(value1, value2) {
  if (value1 === 0 && value2 === 0) return 0; // Avoid division by zero if both values are zero
  if (value1 === 0 || value2 === 0) return 100; // If one value is zero, the difference is 100%

  const average = (value1 + value2) / 2;
  const difference = Math.abs(value1 - value2);
  const percentageDiff = (difference / average) * 100;

  // console.log(percentageDiff);
  return percentageDiff;
}

export function isWeekend(date) {
  const day = date.getDay();
  return day === 0 || day === 6; // Sunday = 0, Saturday = 6
}

export function isUSHoliday(date) {
  const year = date.getFullYear();

  // List of fixed date holidays (month, day)
  const fixedHolidays = [
    { month: 0, day: 1 }, // New Year's Day (January 1)
    { month: 6, day: 4 }, // Independence Day (July 4)
    { month: 11, day: 25 }, // Christmas Day (December 25)
  ];

  // List of holidays that fall on specific weekdays (month, week, weekday)
  const weekdayHolidays = [
    { month: 0, week: 3, weekday: 1 }, // Martin Luther King Jr. Day (3rd Monday of January)
    { month: 1, week: 3, weekday: 1 }, // Presidents' Day (3rd Monday of February)
    { month: 4, week: -1, weekday: 1 }, // Memorial Day (Last Monday of May)
    { month: 8, week: 1, weekday: 1 }, // Labor Day (1st Monday of September)
    { month: 9, week: 2, weekday: 1 }, // Columbus Day (2nd Monday of October)
    { month: 10, week: 4, weekday: 4 }, // Thanksgiving Day (4th Thursday of November)
  ];

  // Check fixed date holidays
  for (const holiday of fixedHolidays) {
    if (date.getMonth() === holiday.month && date.getDate() === holiday.day) {
      return true;
    }
  }

  // Check holidays that fall on specific weekdays
  for (const holiday of weekdayHolidays) {
    if (date.getMonth() === holiday.month) {
      const firstDayOfMonth = new Date(year, holiday.month, 1).getDay();
      const offset = (holiday.weekday - firstDayOfMonth + 7) % 7;
      const holidayDate =
        holiday.week > 0
          ? (holiday.week - 1) * 7 + offset + 1
          : new Date(year, holiday.month + 1, 0).getDate() -
            ((new Date(year, holiday.month + 1, 0).getDay() +
              7 -
              holiday.weekday) %
              7);
      if (date.getDate() === holidayDate) {
        return true;
      }
    }
  }

  // Special case: if Independence Day, New Year's Day, or Christmas Day falls on a weekend, it's observed on the closest weekday
  const observedHolidays = [
    { month: 0, day: 1 }, // New Year's Day (January 1)
    { month: 6, day: 4 }, // Independence Day (July 4)
    { month: 11, day: 25 }, // Christmas Day (December 25)
  ];

  for (const holiday of observedHolidays) {
    if (
      date.getMonth() === holiday.month &&
      (date.getDay() === 0 || date.getDay() === 6)
    ) {
      if (date.getDay() === 6 && date.getDate() === holiday.day - 1)
        return true; // Observed on the previous Friday
      if (date.getDay() === 0 && date.getDate() === holiday.day + 1)
        return true; // Observed on the following Monday
    }
  }

  return false;
}
