import download from "downloadjs";
import nextBase64 from "next-base64";

export const numberWithCommas = (number) => {
  let convertToNumber = number ? parseFloat(number) : 0;
  let removeDecimal = convertToNumber?.toFixed(0);
  const numStr = removeDecimal + "";
  const x = numStr.split(".");
  let x1 = x[0];
  const x2 = x.length > 1 ? "." + x[1] : "";
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1" + "," + "$2");
  }
  return x1 + x2;
};

const addZeroes = (num, decimal) => {
  let convertToString = num?.toString();
  let numberWithZero = parseFloat(
    Number(num).toFixed(
      Math.max(convertToString?.split(".")[1]?.length, 4) || 4
    )
  );
  if (decimal) {
    return numberWithZero?.toFixed(decimal);
  } else {
    return numberWithZero?.toFixed(2);
  }
};

export const numberWithCommasAndDecimal = (number, decimal) => {
  let convertToNumber = number ? parseFloat(number) : 0;
  let numberWithZeroes = addZeroes(convertToNumber, decimal);
  const numStr = numberWithZeroes + "";
  const x = numStr.split(".");
  let x1 = x[0];
  const x2 = x.length > 1 ? "." + x[1] : "";
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1" + "," + "$2");
  }
  return x1 + x2;
};

export const nFormatter = (num) => {
  let number = num?.toString()?.replace(/[^0-9.]/g, "");
  if (number < 1000) {
    return number;
  }
  let si = [
    { v: 1e3, s: "K" },
    { v: 1e6, s: "M" },
    { v: 1e9, s: "B" },
    { v: 1e12, s: "T" },
    { v: 1e15, s: "P" },
    { v: 1e18, s: "E" },
  ];
  let index;
  for (index = si.length - 1; index > 0; index--) {
    if (number >= si[index].v) {
      break;
    }
  }
  return (
    (number / si[index].v)
      ?.toFixed(2)
      ?.replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s
  );
};

export const downloadCSVFile = (data, file) => {
  const contentType = "application/csv";
  const base64decoded = nextBase64.decode(data);
  download(base64decoded, decodeURIComponent(file), contentType);
};

export const downloadPDF = (data, file) => {
  const contentType = "application/pdf";
  const base64decoded = nextBase64.decode(data);
  download(base64decoded, decodeURIComponent(file), contentType);
};
