import React, { useState, useRef } from "react";
import "../SignUp/signup.scss";
import pdf from "../../Assets/Icon/pdf.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import validator from "validator";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import emailjs from "@emailjs/browser";

function SignUp() {
  const form = useRef();
  const handleInput = (phone) => {
    setData((prevState) => ({ ...prevState, ["phone_number"]: phone }));
  };
  const [loader, setLoader] = useState(false);

  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    product: "",
    email: "",
  });
  const [select, setSelected] = useState();
  const handler = (e, name) => {
    const value = e.value;
    setSelected(e);
    setData((prevState) => ({ ...prevState, [name]: value }));
  };
  const handlechange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({ ...prevState, [name]: value }));
  };

  const [error, setError] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    product: "",
  });
  const checkdata = (data) => {
    let localError = {};

    if (!validator.isEmail(data.email)) {
      localError.email = "Please enter a valid email address";
    }
    if (validator.isEmpty(data.first_name)) {
      localError.first_name = "Please enter your first name";
    }
    if (validator.isEmpty(data.last_name)) {
      localError.last_name = "Please enter your last name";
    }
    if (validator.isEmpty(data?.phone_number?.toString())) {
      localError.phone_number = "Please enter your mobile number";
    }
    if (validator.isEmpty(data?.product?.toString())) {
      localError.product = "Please select product.";
    }
    return { localError, isValid: Object.keys(localError).length <= 0 };
  };
  const onFormSubmit = (e) => {
    e.preventDefault();
    const { localError, isValid } = checkdata(data);
    if (isValid) {
      setError({});
      setLoader(true);
      emailjs
        .sendForm(
          "service_8asu0xk",
          "template_hpd0onb",
          form.current,
          "HDrMLazpnyUdz0-qZ"
        )
        .then(
          (result) => {
            toast.success("Email Send Succesfully");
            setData({
              first_name: "",
              last_name: "",
              phone_number: "",
              product: "",
              email: "",
            });
            setSelected("");
            setLoader(false);
          },
          (error) => {
            toast.error("Something went wrong. Please try again");
          }
        );
    } else {
      setError(localError);
    }
  };
  const options = [
    { value: "Advisory", label: "Advisory" },
    { value: "Markets", label: "Markets" },
    { value: "Investment", label: "Investment" },
    { value: "Analytics", label: "Analytics" },
    { value: "Gnii", label: "Gnii" }
  ];
  return (
    <React.Fragment>
      <div className="section-signup">
        <div className="signupcontent">
          <div className="signup-text">
            <div className="signup-head">
              <h2>Contact Us</h2>
            </div>
          </div>
          <form className="signup-form" ref={form} onSubmit={onFormSubmit}>
            <div className="form-wrapper">
              <div className="first-row">
                <div className="fullname-wrapper">
                  <div className="firstname-wrapper">
                    <input
                      type="text"
                      className="firstname"
                      placeholder="First name"
                      name="first_name"
                      onChange={handlechange}
                      value={data.first_name}
                    ></input>
                    <p className="error-text">{error?.first_name}</p>
                  </div>
                  <div className="lastname-wrapper">
                    <input
                      type="text"
                      className="lastname"
                      placeholder="Last name"
                      name="last_name"
                      onChange={handlechange}
                      value={data.last_name}
                    ></input>
                    <p className="error-text">{error?.last_name}</p>
                  </div>
                </div>
              </div>
              <div className="second-row">
                <div className="email-wrapper">
                  <input
                    type="email"
                    className="emailadd"
                    placeholder="Enter email address"
                    name="email"
                    onChange={handlechange}
                    value={data.email}
                  ></input>
                  <p className="error-text">{error?.email}</p>
                </div>
              </div>
              <div className="third-row">
                <PhoneInput
                  inputProps={{
                    name: "phone_number",
                    placeholder: "Enter phone number",
                  }}
                  country={"gh"}
                  onChange={(phone) => handleInput(phone)}
                  value={data?.phone_number}
                />
                <p className="error-text">{error?.phone_number}</p>
              </div>
              <div className="fourth-row">
                <Select
                  options={options}
                  name="product"
                  onChange={(e) => handler(e, "product")}
                  value={select}
                />
                <p className="error-text">{error?.product}</p>
              </div>
              <a href="#"></a>
              <div className="submit-form">
                <button className="submit-btn" disabled={loader}>
                  Submit
                </button>
                {loader && (
                  <div class="spinner-border spinner m-5" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </React.Fragment>
  );
}

export default SignUp;
