import React from "react";
import { Link } from "react-router-dom";
import arrowright from "../../Assets/Icon/rightarrow.svg";
import "../HeroSection/herosection.scss";

const HeroSection = () => {
  return (
    <React.Fragment>
      <div className="section-hero">
        <div className="contenthero">
          <div className="c1">
            <div className="head-title">
              <h3>
                <span className="d-block">There's a</span>
                <span className="d-block">Better way to</span>
              </h3>
            </div>
            <div className="rightarrow-div">
              <img
                src={arrowright}
                alt="right arrow"
                className="rightarrow"
                width={80}
                height={70}
              ></img>
            </div>
            <div className="resHorizontal-text">
              <h1 className="centered big-title back moving">
                <span>Raise Capital</span>
                <span>Buy or Sell</span>
                <span>Invest Now</span>
                <span>Get Insights</span>
                <span>Track it All</span>
              </h1>
              <div
                style={{
                  zIndex: 0,
                }}
                className="centered clipping-container"
              >
                <h1 className="centered big-title front moving">
                  <Link
                    to="/Advisory/overview"
                    className="horizontal-listitems"
                  >
                    Raise Capital
                  </Link>
                  <Link to="/Markets/overview" className="horizontal-listitems">
                    Buy or Sell
                  </Link>
                  <Link
                    to="/Investments/overview"
                    className="horizontal-listitems"
                  >
                    Invest Nowd
                  </Link>
                  <Link
                    to="/Analytics/overview"
                    className="horizontal-listitems"
                  >
                    Get Insight
                  </Link>
                  {/* <Link to="/GNII/overview" className="horizontal-listitems">
                    Track it All
                  </Link> */}
                </h1>
              </div>
            </div>
            <div class="scrolling-words-box">
              <ul>
                <li>
                  <Link to="/GNII/overview" className="vertical-listitems">
                    Track it All
                  </Link>
                </li>

                <li>
                  <Link to="/Analytics/overview" className="vertical-listitems">
                    Get Insights
                  </Link>
                </li>
                <li>
                  <Link
                    to="/Investments/overview"
                    className="vertical-listitems"
                  >
                    Invest Now
                  </Link>
                </li>
                <li>
                  <Link to="/Markets/overview" className="vertical-listitems">
                    Buy or Sell
                  </Link>
                </li>
                <li>
                  <Link to="/Advisory/overview" className="vertical-listitems">
                    Raise Capital
                  </Link>
                </li>
                <li>
                  <Link to="/GNII/overview" className="vertical-listitems">
                    Track it All
                  </Link>
                </li>
              </ul>
            </div>
            {/* <div className="vertical-list-anim">
              <div className="verticallist-main">
                <div className="verticalwrapper">
                  <Link to="/GNII/overview" className="vertical-listitems">
                    Track it All
                  </Link>
                  <Link to="/Analytics/overview" className="vertical-listitems">
                    Get Insights
                  </Link>
                  <Link
                    to="/Investments/overview"
                    className="vertical-listitems"
                  >
                    Invest Now
                  </Link>
                  <Link to="/Markets/overview" className="vertical-listitems">
                    Buy or Sell
                  </Link>
                  <Link to="/Advisory/overview" className="vertical-listitems">
                    Raise Capital
                  </Link>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HeroSection;
