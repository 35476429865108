import React, { useEffect, useState } from "react";
import { ArrowUpRight, ArrowDownRight } from "lucide-react";
import { Link, useLocation } from "react-router-dom";
import { Api } from "../../../src/Api";
import { constants, GetDayOfYear } from "../../../src/lib/utils";
import { getDayOfYear } from "date-fns";
import {
  calculateStat,
  calculateYTDChange,
} from "../MutualFunds/comps/ui/Overview";

export const MutualFundCard = ({
  name,
  description,
  ytd,
  to,
  menu,
  fullname,
}) => {
  const [metrics, setmetrics] = useState(null);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    if (menu) {
      setloading(true);
      try {
        let data = [];
        Api.performanceChartYTD(constants[menu]).then((response) => {
          if (response) {
            let rawResponse = response;
            rawResponse?.map((x, i) => {
              if (x?.marketQuoteTimestamp == x?.timestamp) {
                data?.push({
                  label: x?.marketQuoteTimestamp,
                  value: x?.unitPrice,
                  nav: x?.nav,
                  aum: x?.aum,
                });
              }
            });
            // Calculate YTD change after data is populated
            let ytd = calculateYTDChange(data, true);
            setmetrics(ytd);
            setloading(false);
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [menu]);

  return (
    <Link to={to} className="w-fitrounded ">
      <div
        style={{
          border: "solid #212529 0.5px",
        }}
        className="min-h-[170px] w-full bg-[#21252930] p-4 rounded-lg flex flex-col justify-between  hover:bg-zinc-800 transition-colors duration-300"
      >
        <div className="">
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-medium text-white">{name}</h3>
            {!loading ? (
              <div className="flex items-center">
                <span
                  className={`text-sm font-medium ${
                    metrics >= 0 ? "text-green-400" : "text-red-400"
                  }`}
                >
                  {Number(metrics).toFixed(2)}% YTD
                </span>
                {metrics >= 0 ? (
                  <ArrowUpRight className="text-green-400 ml-1 w-3.5 h-3.5" />
                ) : (
                  <ArrowDownRight className="text-red-400 ml-1 w-3.5 h-3.5" />
                )}
              </div>
            ) : (
              <div className="w-[80px] h-[30px] rounded-lg bg-zinc-700 animate-pulse" />
            )}
          </div>
          <text className="text-sm text-zinc-400">{fullname}</text>
          {/* <p className="text-sm text-gray-400 my-2">{description}</p> */}
        </div>
        <p className="text-sm text-gray-400 mt-2">{description}</p>
        {/* <div className=" w-fit bg-transparent border border-gray-700 text-gray-300 text-sm py-2 px-4 rounded transition-colors duration-300">
          Learn More
        </div> */}
      </div>
    </Link>
  );
};

// change PLC to small font
