import React, { useEffect, useState } from "react";
import "../InvestmentGoal/investment_goal.scss";
import { Link, useNavigate } from "react-router-dom";
import { MutualFundCard } from "../Indicies/CardCon";
import { KEYSx } from "../IFunds/IFunds";
import { getDeviceType } from "../../../src/lib/utils";

export const INVESTMENT_GOAL = [
  {
    name: "Cash Plus",
    fullname: "Fixed Alpha Income Fund",
    description:
      "Use short-term investment strategies to boost return maintain liquidity",
    more: "",
    type: "Fixed",
    key: 1,
  },
  {
    name: "Global Tech",
    fullname: "Enhanced Equity Beta Fund",
    description: "Exposure to the top technology stocks in the world.",
    more: "",
    type: "Equity",
    key: 2,
  },
  {
    name: "Global Equity",
    fullname: "Christian Community Mutual Fund",
    description: "Get access to the top 500 companies in the world.",
    more: "",
    type: "Equity",
    key: 3,
  },
  {
    name: "Balanced Growth",
    fullname: "Plus Balanced Fund",
    description:
      "Get access to a balance between global equity and quality fixed income assets.",
    more: "",
    type: "Balanced",
    key: 4,
  },
  {
    name: "DigiSave",
    fullname: "Plus Income Fund",
    description:
      "Investments that provides steady growth while preserving capital.",
    more: "",
    type: "Money Market",
    key: 5,
  },
  {
    name: "Capital Preservation",
    fullname: "Delta Fund",
    description:
      "Offers  high-credit quality investments which  hedge against inflation.",
    more: "",
    type: "Fixed",
    key: 6,
  },
];

function InvestmentGoal() {
  const navigate = useNavigate();
  const [fadeInAni, setFadeInAni] = useState("fade");

  useEffect(() => {
    setFadeInAni(fadeInAni === "fade" ? "fade2" : "fade");
  }, []);

  return (
    <React.Fragment>
      <div className={`investment_goals mt-4 ${fadeInAni}`}>
        <h1>Explore Our Mutual Funds</h1>
        <div className="methodology_head d-flex">
          <p
            className="m-0 align-self-center"
            // onClick={() => {
            //   navigate("/our_methodology");
            // }}
          >
            Investment Focus
          </p>
          {/*<img className="mt-1 align-self-center ms-2" src={ChevronRight} alt="chevron-right"/>*/}
        </div>
        <div
          className={`text-start text-white mt-5 grid ${
            getDeviceType() === "mobile" ? "grid-cols-1" : "grid-col-4 cusGrid"
          } gap-4 `}
        >
          <div className="flex flex-col col-span-1 gap-4">
            <div
              style={{
                borderBottom: "solid #212529 0.5px",
              }}
              className="min-h-fit w-full p-4 flex flex-col justify-between"
            >
              Fixed Income Funds
            </div>
            {INVESTMENT_GOAL?.filter((x) => x?.type === "Fixed")?.map(
              (data, index) => {
                return (
                  <div className="col-span-1">
                    <MutualFundCard
                      name={data?.name}
                      fullname={data?.fullname}
                      description={data?.description}
                      ytd={0}
                      to={`/fund?menu=${data?.key}`}
                      menu={KEYSx[data?.key - 1]}
                    />
                  </div>
                );
              }
            )}
          </div>
          <div className="flex flex-col gap-4">
            <div
              style={{
                borderBottom: "solid #212529 0.5px",
              }}
              className="min-h-fit w-full p-4 flex flex-col justify-between"
            >
              Balanced Funds
            </div>
            {INVESTMENT_GOAL?.filter((x) => x?.type === "Balanced")?.map(
              (data, index) => {
                return (
                  <div className="col-span-1">
                    <MutualFundCard
                      name={data?.name}
                      fullname={data?.fullname}
                      description={data?.description}
                      ytd={0}
                      to={`/fund?menu=${data?.key}`}
                      menu={KEYSx[data?.key - 1]}
                    />
                  </div>
                );
              }
            )}
          </div>
          <div className="flex flex-col gap-4">
            <div
              style={{
                borderBottom: "solid #212529 0.5px",
              }}
              className="min-h-fit w-full p-4 flex flex-col justify-between"
            >
              Equity Funds
            </div>
            {INVESTMENT_GOAL?.filter((x) => x?.type === "Equity")?.map(
              (data, index) => {
                return (
                  <div className="col-span-1">
                    <MutualFundCard
                      name={data?.name}
                      fullname={data?.fullname}
                      description={data?.description}
                      ytd={0}
                      to={`/fund?menu=${data?.key}`}
                      menu={KEYSx[data?.key - 1]}
                    />
                  </div>
                );
              }
            )}
          </div>
          <div className="flex flex-col gap-4">
            <div
              style={{
                borderBottom: "solid #212529 0.5px",
              }}
              className="min-h-fit w-full p-4 flex flex-col justify-between"
            >
              Money Market Funds
            </div>
            {INVESTMENT_GOAL?.filter((x) => x?.type === "Money Market")?.map(
              (data, index) => {
                return (
                  <div className="col-span-1">
                    <MutualFundCard
                      name={data?.name}
                      fullname={data?.fullname}
                      description={data?.description}
                      ytd={0}
                      to={`/fund?menu=${data?.key}`}
                      menu={KEYSx[data?.key - 1]}
                    />
                  </div>
                );
              }
            )}
          </div>
        </div>
        <div className="h-10" />
      </div>
    </React.Fragment>
  );
}

export default InvestmentGoal;
