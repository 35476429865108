import { BASE_API } from "./axios";

export async function checkAccessToken() {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");

  if (!accessToken || !refreshToken) {
    // User is not authenticated
    localStorage.clear();
    window.open("/", "_self");
    return;
  }

  // Decode the JWT to get the expiration time
  const { exp } = JSON.parse(atob(accessToken.split(".")[1]));

  // if (exp * 1000 < Date.now()) {
  // console.log(">>>><<<<<>>>>>><<<<<");
  const response = await fetch(BASE_API + "/public/user/refresh-token", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ refreshToken }),
  }).catch((err) => {
    console.log("REFRESH ERROR", err);
  });

  const data = await response.json();

  if (data.accessToken) {
    // Update the stored access token
    localStorage.setItem("accessToken", data.accessToken);
    localStorage.setItem("refreshToken", data.refreshToken);
  } else {
    // Handle refresh token failure, maybe redirect to login
    localStorage.clear();
    window.open("/", "_self");
    console.log("couldn't refresh");
  }
  // }
}
