import React from "react";
import "../About/about.scss";
import { useParams } from "react-router-dom";

function About(props) {
  const params = useParams();
  // console.log("params>>>", params, props.open);
  const AboutData = [
    {
      title: "About",
      category: [
        {
          subId: "overview",
          subtitle: "Overview ",
          description:
            "Asset management and investment advisory wing, which provides across-the-board investment solutions utilizing a multi manager investment philosophy. We help our clients gain access to the people and perspectives that can help unearth investment opportunities.",
        },
        {
          subId: "Who",
          subtitle: "Who",
          description:
            "We are a diverse team that combines extensive investing,trading and advisory capability with technology to deliver comprehensive solutions.",
        },
        {
          subId: "What",
          subtitle: "What",
          description:
            "Providing what matters to our clients,shareholders and stakeholders is one of tht most powerful ways to deliver long lasting value.",
        },
        {
          subId: "How",
          subtitle: "How",
          description:
            "We create personalized solutions to help our clients unlock value and create sustainable wealth.",
        },
        {
          subId: "Why",
          subtitle: "Why",
          description:
            "We believe empowering our clients, stakeholders and communities to own their financial future is the defining issue of our time.",
        },
      ],
    },
  ];
  return (
    <React.Fragment>
      <div className="section-about">
        {AboutData?.map((values, index) => {
          return (
            <div className="about-content" key={index}>
              <div className="about-heading">
                <span className="horizontal-line d-block"></span>
                <h2 className="about-title">{values.title}</h2>
              </div>
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                {values?.category.map((val, i) => (
                  <div className="accordion-item" key={i}>
                    <h2 className="accordion-header" id="flush-headingTwo">
                      <button
                        className={`accordion-button ${
                          val.subId === "overview" ? "" : "collapsed"
                        }`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-${val.subId}`}
                        aria-expanded="false"
                        aria-controls={`flush-${val.subId}`}
                      >
                        {val.subtitle}
                      </button>
                    </h2>
                    <div
                      id={`flush-${val.subId}`}
                      className={`accordion-collapse collapse ${
                        val.subId === "overview" ? "show" : ""
                      }`}
                      aria-labelledby="flush-headingTwo"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <p>{val.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
}

export default About;
