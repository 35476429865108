"use client";

import { PieChartComp } from "./PieChart";
import { colors } from "../../../../src/lib/utils";
import { useLocation } from "react-router-dom";
import { INDX_KEYS, KEYSx } from "../../IFunds/IFunds";
import { useEffect, useMemo, useState } from "react";
import { Api } from "../../../../src/Api";

import { getDeviceType } from "../../../../src/lib/utils";
import { VerificationForm } from "./Holdings";

export function Fundamentals({ portSum }) {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const { pathname, search } = useLocation();

  let query = useQuery();
  let menu = Number(query.get("menu"));

  let KEYS = pathname == "/fund" ? KEYSx : INDX_KEYS;

  const [data, setData] = useState(null);

  // Memoize the fetch function to avoid re-creating it on every render
  const fetchData = useMemo(() => {
    return () => {
      if (portSum && !data) {
        Api.getCISData(KEYS[menu - 1], parseFloat(portSum?.nav))
          .then((response) => {
            if (response?.status) {
              setData(response?.data);
              // Save data to sessionStorage
              sessionStorage.setItem(
                "cisData" + KEYS[menu - 1],
                JSON.stringify(response?.data)
              );
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    };
  }, [portSum, menu, data]);

  useEffect(() => {
    const savedData = sessionStorage.getItem("cisData" + KEYS[menu - 1]);
    if (savedData) {
      setData(JSON.parse(savedData));
    } else {
      fetchData();
    }
  }, [fetchData]);

  const [isNewUser, setIsNewUser] = useState(true);
  useEffect(() => {
    let result = localStorage.getItem("isUser");
    if (result) {
      setIsNewUser(false);
    }
  }, []);

  if (!data) {
    return (
      <div className="fade w-full mt-4 animate-pulse">
        <div
          className={`min-h-fit w-full grid ${
            getDeviceType() === "mobile" ? "grid-cols-1" : "grid-cols-2"
          } gap-4`}
        >
          <div className="h-[500px]  w-full p-3 px-4 flex rounded-xl col-span-1 bg-zinc-900" />
          <div className="h-[500px]  w-full p-3 px-4 flex rounded-xl col-span-1 bg-zinc-900" />
        </div>
      </div>
    );
  }

  if (isNewUser) {
    return (
      <div className="fade w-full">
        <VerificationForm
          reload={() => {
            setIsNewUser(!isNewUser);
          }}
        />
      </div>
    );
  }

  return (
    <div className="fade w-full">
      <div className="flex items-center w-full justify-between h-[1px] bg-[#58595b7d]" />
      <div
        className={`mt-5 min-h-fit w-full grid ${
          getDeviceType() === "mobile" ? "grid-cols-1" : "grid-cols-2"
        } gap-4`}
      >
        <div className="flex flex-col justify-start items-start min-h-fit  w-full p-3 px-4 flex rounded-xl bg-[#35353538] col-span-1">
          <text className="text-2xl font-bold mr-1 mb-2 ">
            Country Allocation
          </text>
          <div className="w-full flex justify-center items-center py-2 ">
            <PieChartComp data={data?.regionHoldings} />
          </div>

          <div className="flex justify-between items-center w-full mt-2 mb-1 border-b border-b-gray-600 py-3">
            <div className="flex items-center w-[79%]">
              <text className="text-white  text-left">Region</text>
            </div>
            <div className="flex justify-between ">
              <text className="text-white text-right">Weight %</text>
            </div>
          </div>

          <RegionList data={data} />
        </div>
        <div className="flex flex-col justify-start items-start min-h-fit  w-full p-3 px-4 flex rounded-xl bg-[#35353538] col-span-1">
          <text className="text-2xl font-bold mr-1 mb-2">Sector weight</text>

          <div className="w-full flex justify-center items-center py-2">
            <PieChartComp data={data?.sectorHoldings} />
          </div>

          <div className="flex justify-between items-center w-full mt-2 mb-1 border-b border-b-gray-600 py-3">
            <div className="flex items-center w-[79%]">
              <text className="text-white  text-left">Sector</text>
            </div>
            <div className="flex justify-between ">
              <text className="text-white text-right">Weight %</text>
            </div>
          </div>

          {data?.sectorHoldings?.map((x, i) => {
            return (
              <div className="flex justify-between items-center w-full mt-2 mb-1 py-2">
                <div className="flex items-center w-[79%]">
                  <div
                    className="w-8 h-8 mr-1.5 rounded"
                    style={{
                      background: colors[i],
                    }}
                  />
                  <text className="text-muted">
                    {x?.sector != undefined ? x?.sector : "Others"}
                  </text>
                </div>
                <div className="flex justify-between">
                  <text className="text-white font-bold text-right">
                    {Number(x?.equity * 100).toFixed(2)}
                  </text>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

const RegionList = ({ data }) => {
  const MIN_PERCENTAGE = 1;
  let otherEquity = 0;

  const processedHoldings = data?.regionHoldings?.reduce((acc, x) => {
    const equityPercentage = Number(x?.equity * 100).toFixed(2);

    if (equityPercentage < MIN_PERCENTAGE && x?.sector !== "Others") {
      otherEquity += parseFloat(equityPercentage);
    } else {
      acc.push({
        ...x,
        equityPercentage,
      });
    }

    return acc;
  }, []);

  if (otherEquity > 0) {
    // Check if "Others" is already in the data
    const existingOthers = processedHoldings.find(
      (item) => item.sector === "Others"
    );

    if (existingOthers) {
      existingOthers.equityPercentage = (
        parseFloat(existingOthers.equityPercentage) + otherEquity
      ).toFixed(2);
    } else {
      processedHoldings.push({
        sector: "Others",
        equityPercentage: otherEquity.toFixed(2),
      });
    }
  }

  return (
    <>
      {processedHoldings.map((x, i) => {
        return (
          <div
            className="flex justify-between items-center w-full mt-2 mb-1 py-2"
            key={i}
          >
            <div className="flex items-center w-[79%]">
              <div
                className="w-8 h-8 mr-1.5 rounded"
                style={{
                  background: colors[i],
                }}
              />
              <span className="text-muted">
                {String(x?.sector)?.split("(")[0]}
              </span>
            </div>
            <div className="flex justify-between">
              <span className="text-white font-bold text-right">
                {x.equityPercentage}
              </span>
            </div>
          </div>
        );
      })}
    </>
  );
};
