import React, { useLayoutEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { getMaxValue, getMinValue } from "..";
import "moment-timezone";
import { getDeviceType, isMarketOpen } from "../../../../src/lib/utils";

const SparklineComp = ({ data, refresh, price }) => {
  // console.log("RUN");

  const [minMax, setminMax] = useState({
    min: 0,
    max: 0,
  });

  useLayoutEffect(() => {
    if (data?.y?.length) {
      setminMax({
        min: getMinValue(data?.y),
        max: getMaxValue(data?.y),
      });
    }
  }, [data?.y, data?.y?.length, refresh]);

  let series = [
    {
      name: "Price",
      data: data?.y,
    },
  ];

  const data2 = {
    options: {
      chart: {
        // height: 50,
        // width: 100,
        type: "area",
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: false,
        },
        selection: {
          enabled: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: [data?.y[data?.y?.length - 1] < data.y[0] ? "#047857" : "red"],
        width: 1,
        dashArray: 0,
      },
      fill: {
        colors: [data?.y[data?.y?.length - 1] < data.y[0] ? "#047857" : "red"],
        gradient: {
          shade: "dark",
          type: "vertical",
          shadeIntensity: 0.5,
          opacityFrom: 0.7,
          opacityTo: 0.3,
        },
      },
      yaxis: {
        show: false,
        min: minMax.min,
        max: minMax.max,
      },
      xaxis: {
        type: "datetime",
        categories: data?.x,
        labels: {
          show: false,
        },
        axisBorder: {
          show: true,
          height: 0.2,
          width: "100%",
          offsetX: 0,
          offsetY: 2,
          color: "#78909C",
        },
        axisTicks: {
          show: false,
        },
      },
      tooltip: {
        enabled: false,
        theme: "dark",
      },
      grid: {
        show: false,
      },
    },
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: getDeviceType() === "mobile" ? "100%" : "10vw",
        height: 80,
        marginTop: -20,
        marginLeft: -10,
      }}
    >
      <ReactApexChart
        options={data2.options}
        series={series}
        type="area"
        width={"100%"}
        height={"100%"}
      />
    </div>
  );
};

export default SparklineComp;

export const SparklineCompLocal = ({ data, refresh, price }) => {
  // console.log("RUN");

  const [minMax, setminMax] = useState({
    min: 0,
    max: 0,
  });

  useLayoutEffect(() => {
    if (data?.y?.length) {
      setminMax({
        min: getMinValue(data?.y),
        max: getMaxValue(data?.y),
      });
    }
  }, [data?.y, data?.y?.length, refresh]);

  let series = [
    {
      name: "Price",
      data: data?.y,
    },
  ];

  const data2 = {
    options: {
      chart: {
        // height: 50,
        // width: 100,
        type: "area",
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: false,
        },
        selection: {
          enabled: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: [data?.y[data?.y?.length - 2] >= data.y[0] ? "#047857" : "red"],
        width: 1,
        dashArray: 0,
      },
      fill: {
        colors: [data?.y[data?.y?.length - 2] >= data.y[0] ? "#047857" : "red"],
        gradient: {
          shade: "dark",
          type: "vertical",
          shadeIntensity: 0.5,
          opacityFrom: 0.7,
          opacityTo: 0.3,
        },
      },
      yaxis: {
        show: false,
        min: minMax.min,
        max: minMax.max,
      },
      xaxis: {
        type: "datetime",
        categories: data?.x,
        labels: {
          show: false,
        },
        axisBorder: {
          show: true,
          height: 0.2,
          width: "100%",
          offsetX: 0,
          offsetY: 2,
          color: "#78909C",
        },
        axisTicks: {
          show: false,
        },
      },
      tooltip: {
        enabled: false,
        theme: "dark",
      },
      grid: {
        show: false,
      },
    },
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: getDeviceType() === "mobile" ? "100%" : "10vw",
        height: 80,
        marginTop: -20,
        marginLeft: -10,
      }}
    >
      <ReactApexChart
        options={data2.options}
        series={series}
        type="area"
        width={"100%"}
        height={"100%"}
      />
    </div>
  );
};
