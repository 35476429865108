"use client";

import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from "recharts";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../ui/chart";
import { useEffect, useState } from "react";
import { formatDate, getLabelsAtIntervals } from "./LineChart";
import { getMaxValue, getMinValue } from "..";
import { format } from "date-fns";

function numbersInInterval(start, end, interval) {
  let result = [];
  let step = (end - start) / (interval - 1);

  for (let i = 0; i < interval; i++) {
    result.push(start + i * step);
  }

  return result;
}

export function BarChartComp2({ data }) {
  let [chartData, setChartData] = useState([]);

  useEffect(() => {
    setChartData(data);
    console.log(">>>", data);
  }, [data]);

  const chartConfig = {
    value: {
      label: "Percentage",
      color: data[0]?.color,
    },
  };

  const customTickFormatter = (timestamp) => {
    if (timestamp) {
      let dataStr = timestamp?.split("-");
      const time = new Date(`${dataStr[2]}-${dataStr[1]}-${dataStr[0]}`);
      const formattedTime = format(time, "dd MMM yyyy");
      // const status = getStatus(time);
      return formattedTime;
    } else {
      return timestamp;
    }
  };

  return (
    <div className="w-full h-[300px] -ml-1">
      <ChartContainer className=" h-full w-full" config={chartConfig}>
        <BarChart accessibilityLayer data={chartData}>
          <CartesianGrid
            vertical={true}
            horizontal={true}
            strokeWidth={1}
            strokeDasharray={[1, 8]}
            stroke="#4f4e4d"
            syncWithTicks={true}
            horizontalValues={[
              ...numbersInInterval(
                getMinValue(chartData?.map((x) => x?.value)),
                getMaxValue(chartData?.map((x) => x?.value)),
                5
              ),
              0,
            ]?.sort((a, b) => a - b)}
          />
          <XAxis
            dataKey="label"
            repeatCount={false}
            tickMargin={2}
            tickFormatter={(value) => formatDate(value)}
            tickLine={false}
            axisLine={false}
            interval="preserveStartEnd"
            padding={{
              left: 5,
            }}
            ticks={getLabelsAtIntervals(chartData, 10)}
          />
          <YAxis
            dataKey="value"
            // type="category"
            tickLine={false}
            tickMargin={10}
            axisLine={false}
            tickFormatter={(value) => value}
            // width={180}
            hide={true}
            ticks={[
              getMinValue(chartData?.map((x) => x?.value)),
              getMaxValue(chartData?.map((x) => x?.value)),
            ]}
            height={300}
          />
          <ChartTooltip
            cursor={false}
            content={
              <ChartTooltipContent
                className="bg-black p-2"
                myFormatter={customTickFormatter}
              />
            }
            custom={true}
            suffix="%"
          />
          <Bar dataKey="value" fill={data[0]?.color} radius={4} barSize={12} />
        </BarChart>
      </ChartContainer>
    </div>
  );
}
