import React, { useState } from "react";
import "../CatAccordion/cataccordion.scss";
import data from "../../data";
import SubCatDetails from "../SubCatDetails/SubCatDetails";
import gtTop from "../../Assets/Icon/gt-top.svg";
import { Link, useLocation } from "react-router-dom";

const CatAccordion = ({ setCommonClick }) => {
  const [show, setShow] = useState(false);
  const [selectedCat, setSelectedCat] = useState("");
  const closeMenu = () => setShow(!show);
  const location = useLocation();
  const path = location?.pathname?.split("/")[1];
  return (
    <>
      <React.Fragment>
        <div className="res-accordion">
          <input
            type="text"
            onChange={(e) => setSelectedCat(e.target.value)}
            className="accText-search"
            placeholder="Choose category"
            value={selectedCat || ""}
          ></input>
          <div className="icon-right" onClick={() => setShow(!show)}>
            <img src={gtTop} alt="gtTop Icon"></img>
          </div>
        </div>
        {show && (
          <section
            style={{
              zIndex: 0,
            }}
            className="res-sectioncat"
          >
            <div className="accordion" id="accordionExample">
              {data?.map((val, i) => {
                return (
                  <>
                    <div className="accordion-item" key={i}>
                      <h2 className="accordion-header" id="headingOne">
                        <Link
                          to={`${val.title}/overview`}
                          className="text-decoration-none"
                        >
                          <button
                            className={`${
                              val.id === path?.toLowerCase()
                                ? "accordion-button"
                                : "accordion-button collapsed"
                            } `}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${val.id}`}
                            aria-expanded="false"
                            aria-controls={`#collapse${val.id}`}
                            onClick={() => setSelectedCat(val.title)}
                          >
                            {val.title}
                          </button>
                        </Link>
                      </h2>
                      <div
                        id={`collapse${val.id}`}
                        className={`${
                          val.id === path?.toLowerCase()
                            ? "accordion-collapse collapse show"
                            : "accordion-collapse collapse"
                        }`}
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          {val.subData?.map((sub, index) => (
                            <div key={index}>
                              <SubCatDetails
                                subtitle={sub.subtitle}
                                subcatid={sub.subcatID}
                                index={index}
                                title={val.title}
                                closeMenu={closeMenu}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </section>
        )}
        <section className="sectioncat-accordion">
          <div className="accordion" id="accordionExample">
            {data?.map((val, i) => {
              return (
                <>
                  <div className="accordion-item" key={i}>
                    <h2 className="accordion-header" id="headingOne">
                      <Link
                        to={`${val.title}/overview`}
                        className="text-decoration-none"
                      >
                        <button
                          className={` ${
                            val.id === path?.toLowerCase()
                              ? "accordion-button"
                              : "accordion-button collapsed"
                          }  ${
                            val.id === path?.toLowerCase() ? "active-accor" : ""
                          }`}
                          type="button"
                          onClick={() => setCommonClick(false)}
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${val.id}`}
                          aria-expanded="false"
                          aria-controls={`#collapse${val.id}`}
                        >
                          {val.title}
                        </button>
                      </Link>
                    </h2>
                    <div
                      id={`collapse${val.id}`}
                      className={`${
                        val.id === path?.toLowerCase()
                          ? "accordion-collapse collapse show"
                          : "accordion-collapse collapse"
                      }`}
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        {val.subData?.map((sub, index) => (
                          <div key={index}>
                            <SubCatDetails
                              subtitle={sub.subtitle}
                              subcatid={sub.subcatID}
                              index={index}
                              title={val.title}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </section>
      </React.Fragment>
    </>
  );
};

export default CatAccordion;
