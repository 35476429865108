import React, { useState, useEffect } from "react";
import uparrow from "../../../Assets/Icon/uparrow.svg";
import "../Stats/stats.scss";
import data from "../../../data";
import { useLocation } from "react-router-dom";

function Stats(props) {
  const params = useLocation();
  const [animationClass, setAnimationClass] = useState("fade-state");
  useEffect(() => {
    if (animationClass === "fade-state") {
      setAnimationClass("fade2-state");
    } else {
      setAnimationClass("fade-state");
    }
  }, [params.pathname]);
  return (
    <React.Fragment>
      <div className="section-state">
        {data
          ?.filter((item) => item.title === props.props.title)
          .map((value) =>
            value.stats?.map((values, id) => (
              <div
                className={`main ${
                  animationClass === "fade-state" ? "fade2-state" : "fade-state"
                }`}
                key={id}
              >
                <div className="stat-title">
                  <h2>{values.maintitle}</h2>
                </div>
                <div className="stat-content">
                  <div className="statheading-wrapper">
                    {values.statHeading?.map((head, i) => (
                      <div className="statdata-wrapper" key={i}>
                        <div className="statheading">
                          <img
                            src={uparrow}
                            alt="up arrow"
                            className="d-block me-3 mt-2 uparrow"
                            height={16}
                            width={16}
                          ></img>
                          <h2>{head.title}</h2>
                        </div>
                        <div className="stat-bottom">
                          {head.statnumber?.map((num, index) => {
                            return (
                              <div className="number" key={index}>
                                <span className="common-num">{num.common}</span>
                                <span className="num">{num.number}</span>
                                <span className="num-size">{num.size}</span>
                              </div>
                            );
                          })}
                          <div className="statdesc">
                            <p>{head.statdesc}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))
          )}
      </div>
    </React.Fragment>
  );
}

export default Stats;
