import React, { useEffect, useState } from "react";
import "../Overview/overview.scss";
import gt from "../../../Assets/Icon/gt.svg";
import { Link } from "react-router-dom";
import data from "../../../data";
import { useLocation } from "react-router-dom";

function Overview(props) {
  const params = useLocation();
  const [animationClass, setAnimationClass] = useState("fadeUp");
  useEffect(() => {
    if (animationClass === "fadeUp") {
      setAnimationClass("fadeUp2");
    } else {
      setAnimationClass("fadeUp");
    }
  }, [params.pathname]);

  const handleReadMore = () => {
    props.setTab("solution");
  };

  return (
    <React.Fragment>
      {data
        ?.filter((item) => item.title === props.props.title)
        .map((value) =>
          value.overview?.map((values, id) => {
            return (
              <div
                className={`overview-content ${
                  animationClass === "fadeUp" ? "fadeUp2" : "fadeUp"
                }`}
                key={id}
              >
                <div className="overviewHead">
                  <h2>{values.heading}</h2>
                </div>
                <div className="overviewDesc">
                  <p>{values.description}</p>
                  <Link
                    onClick={handleReadMore}
                    to=""
                    className="readmore"
                    state={values}
                  >
                    {values.link}
                    <img
                      src={gt}
                      alt="gt icon"
                      className="gticon"
                      width={6}
                      height={11}
                    />
                  </Link>
                </div>
              </div>
            );
          })
        )}
    </React.Fragment>
  );
}

export default Overview;
