import React, { useEffect, useState, useref } from "react";
import ham from "../../Assets/Icon/ham.svg";
import cross from "../../Assets/Icon/cross.svg";
import logo from "../../Assets/Icon/logo2.png";
import "../Header/header.scss";
import { Link, NavLink, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { MAIN_TITLE_LOGO } from "../IFunds/IFunds";

function Header({ setCommonClick, commonClick }) {
  const { pathname } = useLocation();
  const [mobile, setMobile] = useState(false);
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query = useQuery();
  let page = query.get("page");

  const ToggleClass = () => {
    if (window.innerWidth > 650) {
      setCommonClick(!commonClick);
    } else {
      setMobile(!mobile);
    }
  };

  return (
    <main className="header-style">
      <header className="section-header">
        <div className="container">
          <div className={commonClick ? "headernav" : "headernavheight"}>
            <div className="headerNav1">
              <div className="logo">
                <Link to="/home" className="d-inline-block">
                  <img src={logo} alt="logo icon" width={180} heigh={40} />
                </Link>
                {/* <NavigationMenuComp /> */}
                <div className="navbar-items">
                  {/* <Link
                    to="/investment_goal"
                    state={{ visible: false }}
                    className="speakwithus fs-16 ff-regular"
                    style={{
                      color:
                        pathname == "/fund" || pathname == "/investment_goal"
                          ? "#be9a2b"
                          : "white",
                    }}
                  >
                    Mutual Funds
                  </Link> */}
                  <div
                    className="relative newtab"
                    onMouseOver={() => {
                      document.getElementById("newtabbody").style.height =
                        "300px";
                    }}
                    onMouseOut={() => {
                      document.getElementById("newtabbody").style.height =
                        "0px";
                    }}
                  >
                    <Link
                      to="/investment_goal"
                      state={{ visible: false }}
                      className="speakwithus fs-16 ff-regular"
                      style={{
                        color:
                          pathname == "/fund" || pathname == "/investment_goal"
                            ? "#be9a2b"
                            : "white",
                      }}
                    >
                      Mutual Funds
                    </Link>
                  </div>
                  <div
                    className="relative newtab2"
                    onMouseOver={() => {
                      document.getElementById("newtabbody2").style.height =
                        "310px";
                    }}
                    onMouseOut={() => {
                      document.getElementById("newtabbody2").style.height =
                        "0px";
                    }}
                  >
                    <Link
                      to="/indicies"
                      state={{ visible: false }}
                      className="speakwithus fs-16 ff-regular"
                      style={{
                        color:
                          pathname == "/indicies" || pathname == "/idx"
                            ? "#be9a2b"
                            : "white",
                      }}
                    >
                      Indicies
                    </Link>
                  </div>
                  {/* <Link
                    to="/indicies"
                    state={{ visible: false }}
                    className="speakwithus fs-16 ff-regular"
                    style={{
                      color:
                        pathname == "/indicies" || pathname == "/idx"
                          ? "#be9a2b"
                          : "white",
                    }}
                  >
                    Indicies
                  </Link> */}
                </div>
              </div>
              <div className="navbar-items">
                {/*Hide above Link and added two new below*/}

                {page == "2" ? (
                  <div
                    style={{
                      cursor: "not-allowed",
                    }}
                    // onClick={() => setMobile(!mobile)}
                    className="btn-primary2"
                  >
                    Get Started
                  </div>
                ) : (
                  <Link
                    to={"/create_account"}
                    // onClick={() => setMobile(!mobile)}
                    className="btn-primary2"
                  >
                    Get Started
                  </Link>
                )}
                <Link
                  to="/login"
                  state={{ visible: false }}
                  className="btn-primary3"
                >
                  Login
                </Link>

                {commonClick ? (
                  <Link to="/home">
                    <img
                      src={cross}
                      alt="Hamburgor Icon"
                      onClick={ToggleClass}
                      className="hamIcon"
                    ></img>
                  </Link>
                ) : (
                  // <Link to="/home">
                  <img
                    src={ham}
                    alt="Hamburgor Icon"
                    onClick={ToggleClass}
                    className="hamIcon"
                  />
                  // </Link>
                )}
              </div>
            </div>
            <CSSTransition in={commonClick} timeout={300} classNames="show">
              <div className="list-items">
                {commonClick && (
                  <ul className="list-unstyled items-list d-flex">
                    <li className="item-list">
                      <NavLink to="/about" className="item-link">
                        About
                      </NavLink>
                    </li>
                    <li className="item-list">
                      <NavLink to="/people" className="item-link">
                        People
                      </NavLink>
                    </li>
                    <li className="item-list">
                      <NavLink to="/careers" className="item-link">
                        Careers
                      </NavLink>
                    </li>
                    <li className="item-list">
                      <NavLink to="/developers" className="item-link">
                        Developer
                      </NavLink>
                    </li>
                    {/* <li className="item-list">
                      <Link to="/developers" className="item-link">
                        Developer
                      </Link>
                    </li> */}
                    {/*<li className="item-list">*/}
                    {/*  <NavLink to="/press" className="item-link">*/}
                    {/*    Press*/}
                    {/*  </NavLink>*/}
                    {/*</li>*/}
                    {/*<li className="item-list">*/}
                    {/*  <NavLink to="/events" className="item-link">*/}
                    {/*    Events*/}
                    {/*  </NavLink>*/}
                    {/*</li>*/}
                  </ul>
                )}
              </div>
            </CSSTransition>
          </div>

          {mobile ? (
            <div
              style={{
                zIndex: 9999,
              }}
              className="mobileRes-nav width-auto"
              id="mobileres-nav"
            >
              <div className="mobile-navitems">
                <div className="mobile-hamicon">
                  <img
                    src={cross}
                    alt="Hamburgor Icon"
                    onClick={ToggleClass}
                    className="hamIcon"
                  ></img>
                </div>

                <div className="mobile-list">
                  <ul className="list-unstyled">
                    <li className="item-list">
                      <NavLink
                        to="/investment_goal"
                        className="item-link"
                        onClick={() => setMobile(!mobile)}
                      >
                        Mutual Funds
                      </NavLink>
                    </li>
                    <li className="item-list">
                      <NavLink
                        to="/indicies"
                        className="item-link"
                        onClick={() => setMobile(!mobile)}
                      >
                        Indices
                      </NavLink>
                    </li>
                    <li className="item-list">
                      <NavLink
                        to="/about"
                        className="item-link"
                        onClick={() => setMobile(!mobile)}
                      >
                        About
                      </NavLink>
                    </li>
                    <li className="item-list">
                      <NavLink
                        to="/people"
                        className="item-link"
                        onClick={() => setMobile(!mobile)}
                      >
                        People
                      </NavLink>
                    </li>
                    <li className="item-list">
                      <NavLink
                        to="/careers"
                        className="item-link"
                        onClick={() => setMobile(!mobile)}
                      >
                        Careers
                      </NavLink>
                    </li>
                    <li className="item-list">
                      <NavLink
                        to="/developers"
                        className="item-link"
                        onClick={() => setMobile(!mobile)}
                      >
                        Developer
                      </NavLink>
                    </li>
                    {/*<li className="item-list">*/}
                    {/*  <NavLink*/}
                    {/*    to="/press"*/}
                    {/*    className="item-link"*/}
                    {/*    onClick={() => setMobile(!mobile)}*/}
                    {/*  >*/}
                    {/*    Press*/}
                    {/*  </NavLink>*/}
                    {/*</li>*/}
                    {/*<li className="item-list">*/}
                    {/*  <NavLink*/}
                    {/*    to="/events"*/}
                    {/*    className="item-link"*/}
                    {/*    onClick={() => setMobile(!mobile)}*/}
                    {/*  >*/}
                    {/*    Events*/}
                    {/*  </NavLink>*/}
                    {/*</li>*/}
                  </ul>
                </div>
                {/*<Link*/}
                {/*  to="/investnow"*/}
                {/*  onClick={() => setMobile(!mobile)}*/}
                {/*  className="speakwithus fs-18 ff-regular"*/}
                {/*>*/}
                {/*  Get Started-2*/}
                {/*</Link>*/}

                {/* <Link
                  to="/investment_goal"
                  onClick={() => setMobile(!mobile)}
                  className="speakwithus fs-18 ff-regular"
                >
                  Mutual Funds
                </Link> */}

                {/* {page == 2 ? (
                  <div
                    style={{
                      cursor: "not-allowed",
                    }}
                    // onClick={() => setMobile(!mobile)}
                    className="btn-primary2"
                  >
                    Create Account
                  </div>
                ) : (
                  <Link
                    to={"/create_account"}
                    // onClick={() => setMobile(!mobile)}
                    className="btn-primary2"
                  >
                    Create Account
                  </Link>
                )} */}
              </div>
            </div>
          ) : (
            <div className="mobileRes-nav width-0" id="mobileres-nav">
              <div className="mobile-navitems">
                <div className="mobile-hamicon">
                  <img
                    src={cross}
                    alt="Hamburgor Icon"
                    onClick={ToggleClass}
                    className="hamIcon"
                  ></img>
                </div>

                <div className="mobile-list">
                  <ul className="list-unstyled">
                    <li className="item-list">
                      <NavLink
                        to="/about"
                        className="item-link"
                        onClick={() => setMobile(!mobile)}
                      >
                        About
                      </NavLink>
                    </li>
                    <li className="item-list">
                      <NavLink
                        to="/people"
                        className="item-link"
                        onClick={() => setMobile(!mobile)}
                      >
                        People
                      </NavLink>
                    </li>
                    <li className="item-list">
                      <NavLink
                        to="/careers"
                        className="item-link"
                        onClick={() => setMobile(!mobile)}
                      >
                        Careers
                      </NavLink>
                    </li>
                    <li className="item-list">
                      <NavLink
                        to="/press"
                        className="item-link"
                        onClick={() => setMobile(!mobile)}
                      >
                        Press
                      </NavLink>
                    </li>
                    <li className="item-list">
                      <NavLink
                        to="/events"
                        className="item-link"
                        onClick={() => setMobile(!mobile)}
                      >
                        Events
                      </NavLink>
                    </li>
                  </ul>
                </div>
                {/*<Link*/}
                {/*  to="/investnow"*/}
                {/*  onClick={() => setMobile(!mobile)}*/}
                {/*  state={{ visible: false }}*/}
                {/*  className="speakwithus fs-18 ff-regular"*/}
                {/*>*/}
                {/*  Get Started*/}
                {/*</Link>*/}
              </div>
            </div>
          )}
        </div>
      </header>
      <div
        onMouseOver={() => {
          document.getElementById("newtabbody").style.height = "300px";
        }}
        onMouseOut={() => {
          document.getElementById("newtabbody").style.height = "0px";
        }}
        id="newtabbody"
        className="newtabbody w-full h-fit bg-black  text-white flex flex-col justify-start items-start fixed"
      >
        <div className="w-full flex grid grid-cols-4 mb-5 pt-5 gap-2 newtabsubbody">
          <div className="flex flex-col justify-start items-start">
            <text className="font-bold mb-4">FIXED INCOME FUNDS</text>

            <Link
              className="mb-3 flex gap-1"
              to={"/fund?menu=1"}
              reloadDocument={true}
            >
              <img
                className="rounded bg-zinc-400"
                src={MAIN_TITLE_LOGO[1]?.logo}
                style={{
                  width: 25,
                  height: 25,
                  objectFit: "contain",
                }}
              />
              <text className="mb-3 text-left">Fixed Income Alpha Fund</text>
            </Link>

            <Link
              className="mb-3 flex gap-1"
              to={"/fund?menu=6"}
              reloadDocument={true}
            >
              <img
                className="rounded bg-zinc-400"
                src={MAIN_TITLE_LOGO[6]?.logo}
                style={{
                  width: 25,
                  height: 25,
                  objectFit: "contain",
                }}
              />
              <text className="mb-3 text-left">Delta Fund</text>
            </Link>
          </div>
          <div className="flex flex-col justify-start items-start ">
            <text className="font-bold mb-4">BALANCED FUNDS</text>

            <Link
              className="mb-3 flex gap-1"
              to={"/fund?menu=4"}
              reloadDocument={true}
            >
              <img
                className="rounded bg-zinc-400"
                src={MAIN_TITLE_LOGO[4]?.logo}
                style={{
                  width: 25,
                  height: 25,
                  objectFit: "contain",
                }}
              />
              <text className="text-left">Plus Balanced Fund</text>
            </Link>
          </div>
          <div className="flex flex-col justify-start items-start ">
            <text className="font-bold mb-4">EQUITY FUNDS</text>

            <Link
              className="mb-3 flex gap-1"
              to={"/fund?menu=2"}
              reloadDocument={true}
            >
              <img
                className="rounded bg-zinc-400"
                src={MAIN_TITLE_LOGO[2]?.logo}
                style={{
                  width: 25,
                  height: 25,
                  objectFit: "contain",
                }}
              />
              <text className="mb-3 text-left">Enhanced Equity Beta Fund</text>
            </Link>
            <Link
              className="mb-3 flex gap-1"
              to={"/fund?menu=3"}
              reloadDocument={true}
            >
              <img
                className="rounded bg-zinc-400"
                src={MAIN_TITLE_LOGO[3]?.logo}
                style={{
                  width: 25,
                  height: 25,
                  objectFit: "contain",
                }}
              />
              <text className="text-left">Christian Community Mutual Fund</text>
            </Link>
          </div>
          <div className="flex flex-col justify-start items-start ml-4">
            <text className="font-bold mb-4">MONEY MARKET FUNDS</text>

            <Link
              className="mb-3 flex gap-1"
              to={"/fund?menu=5"}
              reloadDocument={true}
            >
              <img
                className="rounded bg-zinc-400"
                src={MAIN_TITLE_LOGO[5]?.logo}
                style={{
                  width: 25,
                  height: 25,
                  objectFit: "contain",
                }}
              />
              <text className="text-left">Plus Income Fund</text>
            </Link>
          </div>
        </div>
        <Link
          className="w-full bg-zinc-900 newtabsubbody "
          to={"/investment_goal"}
        >
          <div className="w-full h-[45px] flex items-center justify-start gap-2 cursor-pointer hover:bg-zinc-800">
            View All <ArrowRight size={16} />
          </div>
        </Link>
      </div>

      {/* 2 */}
      <div
        onMouseOver={() => {
          document.getElementById("newtabbody2").style.height = "310px";
        }}
        onMouseOut={() => {
          document.getElementById("newtabbody2").style.height = "0px";
        }}
        id="newtabbody2"
        className="newtabbody2 w-full h-fit bg-black  text-white flex flex-col justify-start items-start fixed"
      >
        <div className="w-full grid grid-cols-4 mb-3 pt-5 gap-2 newtabsubbody2">
          <div className="flex flex-col justify-start items-start">
            <text className="font-bold mb-4">EQUITY</text>

            <Link
              className="mb-3 flex gap-1"
              to={"/idx?menu=1"}
              reloadDocument={true}
            >
              <img
                className="rounded bg-zinc-400"
                src={MAIN_TITLE_LOGO[7]?.logo}
                style={{
                  width: 25,
                  height: 25,
                  objectFit: "contain",
                }}
              />
              <text className="mb-3 text-left">Global Multi Sector Index</text>
            </Link>

            <Link
              className="mb-3 flex gap-1"
              to={"/idx?menu=2"}
              reloadDocument={true}
            >
              <img
                className="rounded bg-zinc-400"
                src={MAIN_TITLE_LOGO[7]?.logo}
                style={{
                  width: 25,
                  height: 25,
                  objectFit: "contain",
                }}
              />
              <text className="text-left">Ghana Equity total Return Index</text>
            </Link>
          </div>
          <div className="flex flex-col justify-start items-start ">
            <text className="font-bold mb-4">FIXED INCOME</text>

            <Link
              className="mb-3 flex gap-1"
              to={"/idx?menu=5"}
              reloadDocument={true}
            >
              <img
                className="rounded bg-zinc-400"
                src={MAIN_TITLE_LOGO[7]?.logo}
                style={{
                  width: 25,
                  height: 25,
                  objectFit: "contain",
                }}
              />
              <text className="mb-3 text-left">Ghana Eurobond Index</text>
            </Link>

            <Link
              className="mb-3 flex gap-1"
              to={"/idx?menu=4"}
              reloadDocument={true}
            >
              <img
                className="rounded bg-zinc-400"
                src={MAIN_TITLE_LOGO[7]?.logo}
                style={{
                  width: 25,
                  height: 25,
                  objectFit: "contain",
                }}
              />
              <text className="text-left">Domestic Bond Index</text>
            </Link>

            <Link
              className="mb-3 flex gap-1"
              to={"/idx?menu=3"}
              reloadDocument={true}
            >
              <img
                className="rounded bg-zinc-400"
                src={MAIN_TITLE_LOGO[7]?.logo}
                style={{
                  width: 25,
                  height: 25,
                  objectFit: "contain",
                }}
              />
              <text className="mb-3 text-left">Treasury Index</text>
            </Link>
          </div>
          {/* <div className="flex flex-col justify-start items-start ">
            <text className="font-bold mb-4">FIXED INCOME</text>

            <Link className="mb-3 flex gap-1" to={"/idx?menu=3"} reloadDocument={true}>
              <text className="mb-3 text-left">Treasury Index</text>
            </Link>
          </div> */}
          <div className="flex flex-col justify-start items-start ml-4">
            {/* <text className="font-bold mb-4">MONEY MARKET</text> */}
          </div>
        </div>
        <Link className="w-full bg-zinc-900 newtabsubbody2 " to={"/indicies"}>
          <div className="w-full h-[45px] flex items-center justify-start gap-2 cursor-pointer hover:bg-zinc-800">
            View All <ArrowRight size={16} />
          </div>
        </Link>
      </div>
    </main>
  );
}

export default Header;
