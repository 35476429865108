import {
  INDX_KEYS,
  KEYSx,
  MAIN_TITLE_LOGO,
} from "../../../../../src/components/IFunds/IFunds";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BarChartComp2 } from "../BarChart2";
import { Api } from "../../../../../src/Api";
import { constants, getDeviceType } from "../../../../../src/lib/utils";
import { VerificationForm } from "../Holdings";

// function to get the from the date to the current date minus weekends

function getDaysToMont(numOfMonths) {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();

  // get the n month ago date
  currentDate.setMonth(currentMonth - numOfMonths);

  // Subtract two days
  currentDate.setDate(currentDate.getDate() - 3);

  console.log(currentDate.toISOString());
  return currentDate;
}

// get first day of the year
const getFirstDayOfYear = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const firstDay = new Date(currentYear, 0, 1);
  return firstDay;
};

const getDaysForTime = (chartTime) => {
  switch (chartTime) {
    case "1M":
      return getDaysToMont(1);
    case "3M":
      return getDaysToMont(3);
    case "6M":
      return getDaysToMont(6);
    case "1Y":
      return getDaysToMont(12);
    case "YTD":
      return getFirstDayOfYear();
    default:
      return null;
  }
};

const RiskComp = ({ calcRisk }) => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const { pathname, search } = useLocation();

  let query = useQuery();
  let menu = Number(query.get("menu"));

  let KEYS = pathname === "/fund" ? KEYSx : INDX_KEYS;
  let isIndex = pathname === "/fund" ? false : true;

  const [chartTimeRet, setchartTimeRet] = useState("1M");
  const [returnlineChart, setreturnLineChart] = useState([]);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    Api.performanceChart(constants[KEYS[menu - 1]]).then((response) => {
      if (response) {
        if (response) {
          let rawResponse = response;
          let data = [];
          // let priceDataArr = [];

          rawResponse?.map((x, i) => {
            if (x?.marketQuoteTimestamp == x?.timestamp) {
              // priceDataArr.push(x?.unitPrice);
              data?.push({
                label: x?.marketQuoteTimestamp,
                value: x?.unitPrice,
                color: MAIN_TITLE_LOGO[isIndex ? 4 : menu]?.color,
                nav: x?.nav,
              });
            }
          });

          setreturnLineChart(data);
        }
      }
    });
  }, []);

  //   get data based on time
  useEffect(() => {
    if (returnlineChart?.length) {
      const color = MAIN_TITLE_LOGO[isIndex ? 4 : menu]?.color;
      const days = getDaysForTime(chartTimeRet);

      if (days) {
        let currentDay = new Date();
        let startDay = new Date(days);
        // find the data between the start day and the current day
        let transdata = returnlineChart.filter((x) => {
          let ds = String(x?.label).split("-");
          let date = new Date(ds[2] + "-" + ds[1] + "-" + ds[0]);
          return date >= startDay && date <= currentDay;
        });

        console.log(transdata);

        // const transdata = returnlineChart.slice(-days);

        setChartData(
          transdata?.map((x, i) => ({
            value: i === 0 ? 0 : transdata[i]?.value - transdata[i - 1]?.value,
            label: x?.label,
            color: color,
          }))
        );
      } else {
        // const initialPrice = returnlineChart[0]?.value;
        setChartData(
          returnlineChart.map((x, i) => ({
            // value: calculateCumulativePercentChange(initialPrice, x?.value),
            value:
              i === 0
                ? 0
                : returnlineChart[i]?.value - returnlineChart[i - 1]?.value,
            label: x?.label,
            color: color,
          }))
        );
      }
    }
  }, [returnlineChart, chartTimeRet]);

  const [isNewUser, setIsNewUser] = useState(true);

  useEffect(() => {
    let result = localStorage.getItem("isUser");
    if (result) {
      setIsNewUser(false);
    }
  }, []);

  if (isNewUser) {
    return (
      <div className="fade w-full">
        <VerificationForm
          reload={() => {
            setIsNewUser(!isNewUser);
          }}
        />
      </div>
    );
  }

  return (
    <div className="fade w-full">
      <div className="flex items-center w-full justify-between h-[1px] bg-[#58595b7d]" />

      {chartData?.length ? (
        <div className="min-h-[300px] mt-4 w-full p-3 px-4 flex flex-col justify-between items-start rounded-xl bg-[#35353538]">
          <div
            className={`w-full flex ${
              getDeviceType() === "mobile"
                ? "flex-col items-start"
                : "items-center"
            } justify-between`}
          >
            <text className="text-lg font-bold">
              {KEYS[menu - 1]} Daily Returns Analysis
            </text>

            <div className="flex ">
              {[
                {
                  name: "1M",
                },
                {
                  name: "3M",
                },
                {
                  name: "6M",
                },
                {
                  name: "1Y",
                },
                {
                  name: "YTD",
                },
                {
                  name: "ALL",
                },
              ]?.map((x) => {
                return (
                  <div
                    onClick={() => {
                      setchartTimeRet(x?.name);
                    }}
                    className={`${
                      getDeviceType() === "mobile" ? "ml-0 mt-2" : "ml-2"
                    } w-8 h-8 ${
                      chartTimeRet == x?.name ? "bg-[#58595b7d]" : ""
                    } hover:bg-[#3333335d] rounded-lg text-sm cursor-pointer flex justify-center items-center`}
                  >
                    {x?.name}
                  </div>
                );
              })}
            </div>
          </div>

          <BarChartComp2
            data={chartData?.filter((x) => Math.abs(Number(x?.value)) !== 0)}
          />
        </div>
      ) : (
        <div className="animate-pulse h-[300px] mt-4 w-full p-3 px-4 flex rounded-xl col-span-1 bg-zinc-900" />
      )}
      {/* <div className="min-h-[300px] mt-4 w-full p-3 px-4 flex justify-start items-start rounded-xl bg-[#35353538]">
          <div>
            <text className="text-lg font-bold">Risk Return</text>
          </div>
        </div> */}

      {calcRisk["1yr"]?.return ? (
        <div className="min-h-fit mt-4 w-full p-3 px-4 flex justify-start items-start rounded-xl bg-[#35353538] overflow-x-auto ">
          <table className="tabel w-full">
            <thead
              style={{
                height: 50,
                borderBottom: "1px solid #58595b7d",
              }}
            >
              <tr className="pb-4">
                <th
                  className={`${
                    getDeviceType() === "mobile" ? "min-w-[130px]" : "w-auto"
                  }`}
                >
                  Risk Measures
                </th>
                <th
                  className={`${
                    getDeviceType() === "mobile" ? "min-w-[100px]" : "w-auto"
                  } text-right`}
                >
                  1 Year
                </th>
                <th
                  className={`${
                    getDeviceType() === "mobile" ? "min-w-[100px]" : "w-auto"
                  } text-right`}
                >
                  3 Years
                </th>
                <th
                  className={`${
                    getDeviceType() === "mobile" ? "min-w-[100px]" : "w-auto"
                  } text-right`}
                >
                  5 Years
                </th>
                <th
                  className={`${
                    getDeviceType() === "mobile" ? "min-w-[100px]" : "w-auto"
                  } text-right`}
                >
                  All Time
                </th>
              </tr>
            </thead>
            {[
              {
                name: "Annualized Return (%)",
                value: calcRisk["1yr"]?.return,
                value1: calcRisk["3yr"]?.return,
                value2: calcRisk["5yr"]?.return,
                value3: calcRisk["allTime"]?.return,
              },
              {
                name: "Standard deviation  (%)",
                value: calcRisk["1yr"]?.standardDeviation,
                value1: calcRisk["3yr"]?.standardDeviation,
                value2: calcRisk["5yr"]?.standardDeviation,
                value3: calcRisk["allTime"]?.standardDeviation,
              },
              {
                name: "Average Daily Gain  (%)",
                value: calcRisk["1yr"]?.avgGains,
                value1: calcRisk["3yr"]?.avgGains,
                value2: calcRisk["5yr"]?.avgGains,
                value3: calcRisk["allTime"]?.avgGains || "━━",
              },
              {
                name: "Average Daily Loss  (%)",
                value: calcRisk["1yr"]?.avgLoss,
                value1: calcRisk["3yr"]?.avgLoss,
                value2: calcRisk["5yr"]?.avgLoss,
                value3: calcRisk["allTime"]?.avgLoss || "━━",
              },
            ]?.map((x, i) => {
              return (
                <tbody
                  style={{
                    border: "none",
                    width: "100%",
                  }}
                  className=" text-white justify-center items-center w-full"
                >
                  <tr
                    style={{
                      height: 50,
                    }}
                  >
                    <td>{x?.name}</td>

                    <td className="text-right">{x?.value}</td>
                    <td className="text-right">{x?.value1}</td>
                    <td className="text-right">{x?.value2}</td>
                    <td className="text-right">{x?.value3}</td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        </div>
      ) : (
        <div className="animate-pulse h-[300px] mt-4 w-full p-3 px-4 flex rounded-xl col-span-1 bg-zinc-900" />
      )}
    </div>
  );
};

export default RiskComp;
