import React, { useEffect, useState } from "react";
import "../CreateAccount/create_account.scss";
import InstitutionPage from "./pages/instutition";
import InitPage from "./pages/init";
import { useLocation } from "react-router-dom";
import Register from "./pages/register";
import IndividualPage from "./pages/individual";

function CreateAccount() {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const [fadeInAni, setFadeInAni] = useState("fade");
  const [page, setPage] = useState(0);

  let query = useQuery();
  console.log(query.get("page"));
  let qpage = query.get("page");

  useEffect(() => {
    if (qpage == null) {
      setPage(0);
    }
    setPage(Number(qpage));
  }, [qpage]);

  useEffect(() => {
    if (page === 0) {
      window.history.pushState({}, "", window.location.pathname + "?page=0");
    }
    if (page === 1) {
      window.history.pushState({}, "", window.location.pathname + "?page=1");
    }
    if (page === 2) {
      window.history.pushState({}, "", window.location.pathname + "?page=2");
    }
    if (page === 3) {
      window.history.pushState({}, "", window.location.pathname + "?page=3");
    }
  }, [page]);

  useEffect(() => {
    setFadeInAni(fadeInAni === "fade" ? "fade2" : "fade");
  }, []);

  return (
    <React.Fragment>
      {page === 0 && <InitPage setPage={setPage} />}
      {page === 1 && (
        <Register setPage={setPage} page={page} type="institutional" />
      )}
      {page === 4 && (
        <Register setPage={setPage} page={page} type="individual" />
      )}
      {page === 2 && <InstitutionPage setPage={setPage} />}
      {page === 3 && <IndividualPage setPage={setPage} />}
    </React.Fragment>
  );
}

export default CreateAccount;
